<template>
  <div>
    <v-row v-if="viewData.id">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Full name') }}</strong>
            {{ viewData.fullname }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Type') }}</strong>
            {{ viewData.type[`name_${$i18n.locale}`] }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Phone') }}</strong>
            {{ viewData.phone }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('ID Cart') }}</strong>
            {{ viewData.id_cart }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Occupation') }}</strong>
            {{ viewData.occupation }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Degree') }}</strong>
            {{ viewData.degree }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Nickname') }}</strong>
            {{ viewData.nickname }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Business Name') }}</strong>
            {{ viewData.business_name }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Martial Status') }}</strong>
            {{ viewData.marital_status == 1 ? $t('Single') : $t('Married') }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Husband/Wife Name') }}</strong>
            {{ viewData.spouse_name }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Mother Name') }}</strong>
            {{ viewData.mother_name }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Dos Number Tax Office') }}</strong>
            {{ viewData.dos_number_tax_office }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="8"
        lg="6"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text class="d-flex justify-space-between">
            <strong>{{ $t('Address') }}</strong>
            {{ viewData.address }}
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        v-if="viewData.babies.length"
        cols="12"
      >
        <v-card
          flat
          rounded="lg"
        >
          <v-card-text>
            <v-row
              style="border: 1px solid #ccc"
              class="rounded-lg d-none d-sm-flex"
            >
              <v-col
                cols="12"
                sm="4"
              >
                {{ $t('Child Name') }}
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                {{ $t('Birth Date') }}
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                {{ $t('Occupation') }}
              </v-col>
            </v-row>

            <v-row
              v-for="(baby, index) in viewData.babies"
              :key="baby.id"
              :class="index == 0 ? 'pt-3' : ''"
            >
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-space-between"
              >
                <strong class="d-inline-block d-sm-none">{{ $t('Child Name') }}</strong>
                {{ baby.name }}
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-space-between"
              >
                <strong class="d-inline-block d-sm-none">{{ $t('Birth Date') }}</strong>
                {{ $_date_format(baby.dob) }}
              </v-col>
              <v-col
                cols="12"
                sm="4"
                class="d-flex justify-space-between"
              >
                <strong class="d-inline-block d-sm-none">{{ $t('Occupation') }}</strong>
                {{ baby.occupation }}
              </v-col>

              <v-col cols="12">
                <v-divider v-show="index != viewData.babies.length - 1" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    viewData: { type: Object, default: () => { } },
  },
}
</script>

<style lang="scss" scoped>
</style>
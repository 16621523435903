<template>
  <div>
    <v-tabs
      v-model="tab"
      grow
      show-arrows
      class="rounded-lg"
    >
      <v-tab
        v-for="(item, index) in items"
        :key="index"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items
      v-model="tab"
      class="mt-6 rounded-lg"
      style="background-color: transparent"
    >
      <v-tab-item>
        <info :view-data="viewData" />
      </v-tab-item>

      <v-tab-item>
        <app-add-edit-attachment
          model-name="Shareholder"
          :model-id="Number($route.params.id)"
          :model-attachments="modelAttachments"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import axios from 'axios'
import Info from './Info.vue'

export default {
  components: {
    Info,
  },

  data() {
    return {
      tab: null,
      items: [
        this.$t('Info'),
        this.$t('Attachments'),
      ],
      viewData: {},
      modelAttachments: []
    }
  },

  mounted() {
    this.getViewData().then(() => {
      this.$_section_title({ title: this.viewData.fullname })
    })
    this.getAttachments()
  },

  methods: {
    async getViewData() {
      await axios.get(`shareholder/${this.$route.params.id}/`).then(res => {
        this.viewData = res.data
      })
    },

    getAttachments() {
      let params = {
        model_id: this.$route.params.id,
        model_name: 'shareholder'
      }

      axios.post('attachment/get-attachments/', params).then(res => {
        this.modelAttachments = res.data
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.basil {
  background-color: #fffbe6 !important;
}
.basil--text {
  color: #356859 !important;
}
</style>